<template>
<div class="container">
    <div class="top-panel bg-s-cover">
        <mobile-header title="Auto Laminate" />
    </div>
</div>
</template>

<script>
import {mapState} from 'vuex';
import {REQUEST_ORDER_INFO} from '@/store/mutation-types';
// import wx from 'weixin-js-sdk';
import MobileHeader from '@/components/MobileHeader.vue'
export default {
    data() {
        return {
            client: localStorage.getItem('client')
        }
    },
    components: {
        MobileHeader
    },
    methods: {
        goShare(){
            this.$goPath('/share?couponCode='+this.orderCoupon);
        },
        closeWindow(){
            if(this.client == 'wechat'){
                //wx.closeWindow();
                WeixinJSBridge.call('closeWindow');//微信 
                
            }
            else if (this.client == 'alipay') {
                AlipayJSBridge.call('closeWebview'); //支付宝 关闭窗口（别名）
            }
            else {
                window.cloce();
            }
        }

    },
    computed: {
        ...mapState({
            orderCoupon: state => state.SaleOrder.coupon_code
      })
    },
    created() {
        this.closeWindow();
    }
}
</script>

<style lang="scss" scoped>
.container {
    $imgBoxHeight: 34.67vw;

    .top-panel {
        width: 100%;
        height: 82.67vw;
        background-image: url(~@/assets/mobile/pay_success/top_panel.png);
        background-position: bottom;
        background-repeat: no-repeat;
        position: relative;

        .success-icon {
            width: 16.67vw;
            height: 16.67vw;
            background-image: url(~@/assets/mobile/pay_success/success_icon.png);
            margin: 8vw auto 0;
        }

        .pay-tips-title {
            font-size: 4.8vw;
            line-height: 4.8vw;
            font-weight: 600;
            color: #ffffff;
            margin-top: 8vw;
        }

        .pay-tips-desc {
            color: #ffffff;
            margin-top: 3.87vw;
            font-size: 3.73vw;
            line-height: 3.73vw;
        }

        .phone-img-box {
            width: 34.67vw;
            height: $imgBoxHeight;
            background-image: url(~@/assets/mobile/pay_success/img_box.png);
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);

            .phone-img {
                width: 11.47vw;
                height: 23.87vw;
            }
        }
    }

    .tips {
        font-size: 4.27vw;
        line-height: 4.27vw;
        padding-top: $imgBoxHeight/2;
        margin-top: 8vw;
    }

    .btn {
        width: 84vw;
        height: 10.67vw;
        font-size: 4.27vw;
        line-height: 10.67vw;
    }

    .share-btn {
        color: #ffffff;
        background-image: url(~@/assets/mobile/pay_success/share_btn.png);
        margin: 14.4vw auto 0;
    }

    .close-btn {
        background-image: url(~@/assets/mobile/pay_success/share_btn.png);
        margin: 6.67vw auto 0;
    }

}
</style>
